import {AxiosResponse} from 'axios';
import Api from '@/core/api/Api';
import ApiSettings from '@/core/api/settings/ApiSettings';

import {default as AuthBase} from '@/core/auth/Auth';

export default class Auth extends AuthBase {
  /**
   * Authorize given scope.
   * @param scope
   */
  public static async authorizeScope(scope: string) {
    const authData = {
      grant_type: 'client_credentials',
      client_id: process.env.VUE_APP_API_CLIENT_ID,
      client_secret: process.env.VUE_APP_API_CLIENT_SECRET,
      scope,
    };

    const response: AxiosResponse = await new Api()
      .data(authData)
      .post('/auth/v1/token');

    ApiSettings.token = `${response.data.token_type} ${response.data.access_token}`;
  }
}
