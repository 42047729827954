<template>
  <Card :has-error="isInvalid">
    <div
      class="guests-list-table"
      ref="table"
    >
        <div class="d-flex justify-space-between">
          <div>
            <h2>Stół nr {{ index + 1 }}</h2>
          </div>

          <div>
            <Button
              v-tooltip="'Kliknij, aby dodać stół jako następny.'"
              color="success"
              small
              icon
              @click="addTableAtIndex(index + 1)"
            >
              <Icon name="add"/>
            </Button>
            <Button
              v-tooltip="'Kliknij, aby usunąć stół.'"
              color="error"
              small
              icon
              @click="removeTable(index)"
            >
              <Icon name="delete"/>
            </Button>
          </div>
        </div>

        <div
          style="max-width: 200px;"
          class="mb-5"
        >
          <select-field
            :value="table.type"
            :items="tableTypeOptions"
            @change="type => table.changeType(type)"
          />

          <div
            v-if="displayCommentsField"
            class="guests-list-table__comments_field"
          >
            <div class="form-group" :class="{'has-error': commentsFieldInvalid}">
              <form-field
                :label="commentsFieldLabel"
                :validation="validation.comments"
                column
                required
              >
                <input-field
                  v-model="table.comments"
                  :validation="validation.comments"
                  placeholder="Wpisz..."
                />
              </form-field>
            </div>
          </div>
        </div>

        <ul style="list-style: none; padding: 0; margin-bottom: -20px;">
          <li
            v-for="(side, $sideIndex) in table.sides"
            class="mb-5"
            :key="$sideIndex"
          >
            <h3
              v-if="side.title"
              class="mb-1"
            >
              {{ side.title }}
            </h3>
            <div class="guests-list-table__side">
              <guests-list-table-person
                v-for="(person, $personIndex) in side.people"
                :key="person.timestamp"
                :person="person"
                :validation="validation.sides.$each[$sideIndex].people.$each[$personIndex]"
                :index="$personIndex"
                :initialized="initialized"
                :on-add-person-at-index="(index) => addPersonAtIndex(side, index)"
                :on-delete="(index) => removePerson(side, index)"
              ></guests-list-table-person>

              <div
                v-if="displayNoPeopleInfo"
                class="alert alert-info m0"
              >
                Brak dodanych osób.
              </div>

              <div class="panel-footer">
                <Button
                  prepend-icon="add"
                  small
                  @click="addPersonAtIndex(side, 0)"
                >
                  Osoba na początek
                </Button>

                <Button
                  prepend-icon="add"
                  small
                  @click="addPerson(side)"
                >
                  Osoba na koniec
                </Button>
              </div>
            </div>
          </li>
        </ul>
    </div>
  </Card>
</template>

<script>
  import {VTooltip} from 'v-tooltip';

  import GuestsListTablePerson from './GuestsListTablePerson.vue';

  import Table from '../models/TableModel';

  // import './../../../tooltip.scss'; // TODO
  import {typesData} from '../guestsListConfig';
  import List from '../models/ListModel';
  import Card from '@/shared/resources/components/cards/Card.vue';
  import Button from '@/shared/resources/components/buttons/Button.vue';
  import Icon from '@/shared/resources/components/Icon.vue';
  import CardTitle from '@/shared/resources/components/cards/CardTitle.vue';
  import FormField from '@/shared/resources/components/forms/FormField.vue';
  import InputField from '@/shared/resources/components/forms/fields/InputField.vue';
  import {scrollTo} from '@/core/helpers/utils/HtmlUtils';
  import SelectField from '@/shared/resources/components/forms/fields/SelectField.vue';
  import Field from '@/shared/resources/components/forms/fields/Field.vue';

  const INPUT_REF_PREFIX = 'person';

  export default {
    components: {
      Field,
      SelectField,
      InputField,
      FormField,
      CardTitle,
      Icon,
      Button,
      Card,
      GuestsListTablePerson,
    },

    directives: {
      tooltip: VTooltip,
    },

    props: {
      list: {
        type: List,
        required: true,
      },

      validation: Object,

      table: {
        type: Table,
        required: true,
      },

      index: {
        type: Number,
        required: true,
      },

      validate: {
        type: Boolean,
        default: false,
      },

      initialized: {
        type: Boolean,
        required: true,
      },

      onAddTableAtIndex: {
        type: Function,
        required: true,
      },

      onDelete: {
        type: Function,
        required: true,
      },
    },

    data() {
      return {};
    },

    computed: {
      tableTypeOptions() {
        return Table.TYPES.map((type) => {
          return {
            value: type,
            text: this.$t('modules.guests-lists.tables.type.' + type),
          };
        });
      },

      isInvalid() {
        return !this.table.isValid && this.validate;
      },

      displayNoPeopleInfo() {
        return this.table.count === 0;
      },

      displayCommentsField() {
        return this.list.tablesCommentsNeeded;
      },

      commentsFieldLabel() {
        if (!this.displayCommentsField) {
          return null;
        }

        return typesData[this.list.type].tablesCommentsField ? typesData[this.list.type].tablesCommentsField.label : null;
      },

      commentsFieldPlaceholder() {
        if (!this.displayCommentsField) {
          return null;
        }

        return typesData[this.list.type].tablesCommentsField
          ? typesData[this.list.type].tablesCommentsField.placeholder
          : null;
      },

      commentsFieldInvalid() {
        return !this.table.hasValidComments && this.validate;
      },
    },

    created() {
      if (this.initialized) {
        setTimeout(() => {
          scrollTo(this.$refs.table);
        }, 300);
      }
    },

    methods: {
      addPerson(side) {
        side.addPerson();
      },

      addPersonAtIndex(side, index) {
        side.addPersonAtIndex(index);
      },

      removePerson(side, index) {
        side.removePerson(index);
      },

      addTableAtIndex(index) {
        this.onAddTableAtIndex(index);
      },

      removeTable(index) {
        this.onDelete(index);
      },
    },
  };
</script>

<style lang="scss">
  .guests-list-table {
    margin-bottom: 1.5rem;

    &__comments_field {
      margin-bottom: 25px;
    }

    &__side {
      padding: 1rem;
      border: 1px #ddd solid;
      border-radius: .5rem;
    }

    .v-card__title {
      display: flex;
      justify-content: space-between;
    }

    .panel {
      .panel-header {
        .helper {
          float: right !important;
        }
      }

      &--invalid {
        box-shadow: 0 0 25px rgba(238, 83, 79, 0.75);

        .panel-header {
          color: $error;
        }
      }
    }
  }
</style>
