<template>
  <div class="guests-list">
    <div v-if="displayForm">
      <div class="mb-10">
        <h3 class="section-title">
          <icon
            name="local_offer"
            color="primary"
            left
            large
          />
          Typ imprezy
        </h3>
        <guests-list-types
          :value="list.type"
          :onChange="handleTypeChange"
        ></guests-list-types>
      </div>

      <div v-if="displayFieldset">
        <div
          :class="{'has-error': invalidData}"
          class="mb-10"
        >
          <h3 class="section-title">
            <icon
              name="event"
              color="primary"
              left
              large
            />
            <span>
            Informacje o&nbsp;przyjęciu

              <small class="section-title__error" v-if="invalidData">
                Proszę uzupełnić wszystkie pola.
              </small>
            </span>
          </h3>
          <card>
            <guests-list-data :list="list" :validation="$v.list"/>
          </card>
        </div>

        <div
          v-if="displayNewlywedTable"
          :class="{'has-error': invalidNewlyweds}"
          class="mb-10"
        >
          <h3 class="section-title">
            <icon
              name="favorite_border"
              color="primary"
              left
              large
            />
            <span>
              Stół Państwa Młodych

              <small class="section-title__error" v-if="invalidNewlyweds">
                Proszę uzupełnić wszystkie pola oraz usunąć nieuzupełnione osoby.
              </small>
            </span>
          </h3>

          <div class="row guests-list__newlyweds">
            <div class="col-md-6">
              <div :class="{'has-error': invalidBride}">
                <card title="Pani Młoda">
                  <guests-list-newlywed
                    :newlywed="list.newlyweds.bride"
                    :initialized="initialized"
                    :validation="$v.list.newlyweds.bride"
                    name-label="Imię i nazwisko Pani Młodej"
                  ></guests-list-newlywed>
                </card>
              </div>
            </div>

            <div class="col-md-6">
              <div :class="{'has-error': invalidGroom}">
                <card title="Pan Młody">
                  <guests-list-newlywed
                    :newlywed="list.newlyweds.groom"
                    :initialized="initialized"
                    :validation="$v.list.newlyweds.groom"
                    name-label="Imię i nazwisko Pana Młodego"
                  ></guests-list-newlywed>
                </card>
              </div>
            </div>
          </div>
        </div>

        <div :class="{'has-error': invalidTables}">
          <h3 class="section-title">
            <icon
              name="people"
              color="primary"
              left
              large
            />
            <span>
              Stoły gości

              <small class="section-title__error" v-if="invalidTables">
                Proszę uzupełnić wszystkie nazwiska oraz usunąć nieuzupełnione osoby. Proszę dodać conajmniej 1 stół.
              </small>
            </span>
          </h3>
          <div v-if="displayTables">
            <guests-list-table
              v-for="(table, $index) in list.tables"
              :key="table.timestamp"
              :list="list"
              :validation="$v.list.tables.$each[$index]"
              :table="table"
              :index="$index"
              :initialized="initialized"
              :validate="validate"
              :on-add-table-at-index="addTableAtIndex"
              :on-delete="removeTable"
            >
            </guests-list-table>

            <Button
              color="primary"
              prepend-icon="add"
              @click="addTableAtIndex(0)"
            >
              Stół na początek
            </Button>

            <Button
              color="primary"
              prepend-icon="add"
              @click="addTable"
            >
              Stół na koniec
            </Button>
          </div>
        </div>

        <div
          v-if="displayNoTablesInfo"
          class="alert alert-info"
        >
          Brak stołów. <a @click="addTable">Kliknij tutaj</a>, aby dodać stół do listy.
        </div>

        <divider class="my-10"/>

        <div class="guests-list__send-button">
          <div class="guests-list__send-button__button">
            <Button
              :loading="displayLoader"
              type="button"
              color="primary"
              append-icon="send"
              large
              @click="submit"
            >
              Wyślij
            </Button>
          </div>

          <div
            v-if="displayError"
            class="px-3 flex-grow-1"
          >
            <alert
              message="Wystąpił nieznany błąd, prosimy o&nbsp;kontakt."
              type="error"
            />
          </div>
        </div>
      </div>
    </div>

    <template v-if="displaySuccess">
      <message-alert
        title="Formularz wysłano poprawnie"
        type="success"
      >
        <p>Dziękujemy, formularz wysłano poprawnie.</p>

        <p>
          <divider/>
        </p>

        <p>
          <Button
            color="primary"
            href="https://proarti.pl"
          >
            Przejdź do proarti.pl
          </Button>
        </p>

      </message-alert>
    </template>
  </div>
</template>

<script>
  import GuestsListTable from './GuestsListTable.vue';
  import GuestsListTypes from './GuestsListTypes.vue';
  import GuestsListNewlywed from './GuestsListNewlywed.vue';

  import List from '../models/ListModel';

  import GuestsListData from './GuestsListData.vue';
  import Icon from '@/shared/resources/components/Icon.vue';
  import Card from '@/shared/resources/components/cards/Card.vue';
  import Divider from '@/shared/resources/components/Divider.vue';
  import Button from '@/shared/resources/components/buttons/Button.vue';
  import validation from '@/app/modules/guests-lists/guestsListValidation';
  import Api from '@/core/api/Api';
  import Auth from '@/app/lib/Auth';
  import MessageAlert from '@/app/components/MessageAlert.vue';
  import Alert from '@/shared/resources/components/Alert.vue';
  import Swal from 'sweetalert2';

  export default {
    components: {
      Alert,
      MessageAlert,
      Button,
      Divider,
      Card,
      Icon,
      GuestsListTypes,
      GuestsListData,
      GuestsListNewlywed,
      GuestsListTable,
    },

    data() {
      return {
        list: new List(),
        initialized: false,
        validate: false,
        loading: false,

        success: false,
        error: false,
      };
    },

    validations() {
      return validation(this.list);
    },

    computed: {
      displayTables() {
        return !this.list.hasNoTables;
      },

      displayNewlywedTable() {
        return this.list.newlywedsRequired;
      },

      displayNoTablesInfo() {
        return this.list.hasNoTables;
      },

      displayForm() {
        return !this.success;
      },

      displayFieldset() {
        return !!this.list.type;
      },

      displayError() {
        return this.error;
      },

      displaySuccess() {
        return this.success;
      },

      displayLoader() {
        return this.loading;
      },

      invalidData() {
        return this.$v.list.$dirty && (this.$v.list.date.$invalid || this.$v.list.place.$invalid);
      },

      invalidNewlyweds() {
        return this.invalidBride || this.invalidGroom;
      },

      invalidBride() {
        return this.$v.list.$dirty && this.$v.list.newlyweds.bride.$invalid;
      },

      invalidGroom() {
        return this.$v.list.$dirty && this.$v.list.newlyweds.groom.$invalid;
      },

      invalidTables() {
        return this.$v.list.tables.$dirty && (this.$v.list.tables.$invalid || !this.list.hasTablesValid);
      },

      listType() {
        return this.list.type;
      },
    },

    watch: {
      list: {
        deep: true,
        handler(list) {
          localStorage.setItem('SERVICES_LIST', JSON.stringify(list));
        },
      },
      listType() {
        this.initialized = false;

        setTimeout(() => {
          this.initialized = true;
        }, 1000);
      },
    },

    async created() {
      const storageData = localStorage.getItem('SERVICES_LIST');

      if (storageData) {
        const confirm = await Swal.fire({
          title: 'Znaleziono dane',
          text: 'Wygląda na to, że na tym urządzeniu rozpoczęto uzupełnianie listy gości. Kontynuować?',
          icon: 'warning',
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonColor: '#9661a8',
          cancelButtonColor: '#ACACAC',
          confirmButtonText: 'Tak',
          cancelButtonText: 'Nie, zacznij od nowa',
        });

        if (confirm.isDismissed) {
          this.resetData();
          return;
        }

        const data = JSON.parse(storageData);
        this.list.applyData(data);
      }
    },

    methods: {
      addTable() {
        this.list.addTable();
      },

      addTableAtIndex(index) {
        this.list.addTableAtIndex(index);
      },

      removeTable(index) {
        this.list.removeTable(index);
      },

      handleTypeChange(type) {
        this.list.setType(type);
      },

      resetData() {
        this.list.reset();

        setTimeout(() => {
          localStorage.removeItem('SERVICES_LIST');
        }, 1000);
      },

      async submit() {
        if (this.loading) {
          return;
        }

        this.$v.$touch();
        this.validate = true;

        if (this.$v.$invalid || !this.list.isValid) {
          setTimeout(() => {
            const firstErrorInput = document.querySelector('.v-input.error--text');

            if (firstErrorInput) {
              firstErrorInput.querySelector('input').focus();
            }

            const firstErrorElement = document.querySelector('.has-error');

            if (firstErrorElement) {
              const offset = -75;
              const y = firstErrorElement.getBoundingClientRect().top + window.pageYOffset + offset;

              window.scrollTo({top: y, behavior: 'smooth'});
            }
          }, 100);
        } else {
          this.loading = true;
          this.success = false;
          this.error = false;

          try {
            await Auth.authorizeScope('services.guests-lists');

            const response = await (new Api())
              .data(this.list.dataToSend)
              .post('/services/v1/guests-lists');

            if (response.data.success) {
              this.resetData();
              this.$v.$reset();

              this.success = true;
            } else {
              this.error = true;
            }
          } catch (e) {
            this.error = true;
          }

          this.loading = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .guests-list {
    h3.section-title {
      margin-top: 0;
      margin-bottom: 15px;
      font-size: 1.5em;
      align-items: center;
      display: flex;

      i {
        margin-right: 1rem;
      }

      small {
        display: block;
        font-size: 55%;
        font-weight: normal;
      }
    }

    &__newlyweds {
      .col-md-6 {
        @include sm() {
          margin-bottom: 0;
        }
      }
    }

    &__success {
      padding-left: 100px;

      &__icon {
        position: absolute;
        top: 5px;
        left: 0;
        font-size: 4rem;

        color: $success;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &__content {
        padding-top: 20px;

        p {
          font-size: 1.5em;
          font-weight: bold;
        }
      }
    }

    &__send-button {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;

      @include sm() {
        flex-direction: row-reverse;
      }

      &__button {
        margin-left: auto;
        margin-bottom: 10px;
        padding: 0 10px;
        flex-basis: 100%;

        @include sm() {
          flex-basis: auto;
          margin-bottom: 0;
        }

        &--loader {
          button {
            &:after {
              content: ' ';
              display: block;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              position: absolute;
              z-index: 10;
              border-radius: 50px;
              background-color: rgba(255, 255, 255, 0.5);
              //background-image: url('./../../../../../images/loader.gif'); // TODO
              background-size: 110px 10px;
              background-position: center;
              background-repeat: no-repeat;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
</style>

<style lang="scss">
  .guests-list {
    .has-error {
      h3.section-title, {
        color: $error;

        i {
          color: $error !important;
        }
      }

      > .panel {
        box-shadow: 0 0 25px rgba(238, 83, 79, 0.75);

        .panel-header {
          color: $error;
        }
      }
    }
  }
</style>
