<template>
  <div class="guests-list-date-picker">
    <date-picker
      v-model="date"
      valueType="format"
      :first-day-of-week="1"
      input-class="form-control"
      width="100%"
      :lang="lang"
      :clearable="false"
      :disabled-days="disabledDays"
    ></date-picker>
  </div>
</template>

<script>
  import DatePicker from 'vue2-datepicker';
  import moment from 'moment';

  export default {
    name: 'GuestsListDataDatePicker',

    components: {
      DatePicker,
    },

    props: {
      value: String,

      onChange: {
        type: Function,
        required: true,
      },
    },

    data() {
      return {
        lang: {
          days: ['Nd', 'Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'So'],
          months: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
          pickers: ['następne 7 dni', 'następne 30 dni', 'poprzednie 7 dni', 'poprzednie 30 dni'],
          placeholder: {
            date: 'Wybierz datę...',
            dateRange: 'Wybierz przedział dat...',
          },
        },
      };
    },

    computed: {
      date: {
        get() {
          return this.value;
        },

        set(value) {
          this.onChange(value);
        },
      },
    },

    methods: {
      disabledDays(date) {
        return moment(date).isSameOrBefore(moment());
      },
    },
  };
</script>

<style lang="scss">
  .guests-list-date-picker {
    .form-control {
      color: $secondary;
    }

    .mx-datepicker-popup {
      border: 0;
      border-radius: 4px;
    }

    .mx-calendar-content {
      .cell {

        &:hover {
          background-color: $accent;
          color: $white;
        }

        &.actived {
          color: $primary;
          color: $white;
        }
      }
    }

    .mx-panel-date td.today {
      color: $primary;
      font-weight: bold;
    }
  }
</style>
