import ApiSettings from '@/core/api/settings/ApiSettings';
import Api from '@/core/api/Api';
import {AxiosResponse} from 'axios';

export default abstract class Auth {
  /**
   * Check if user is authenticated
   */
  public static async checkAuth() {
    if (!ApiSettings.token) {
      this.reset();
      return;
    }

    try {
      await new Api()
        .baseUrl(this.apiBaseUrl)
        .get('/user');
    } catch (e) {
      this.reset();
    }
  }

  /**
   * Login
   * @param email
   * @param password
   * @throws ApiError
   */
  public static async login(email: string, password: string) {
    const response: AxiosResponse = await new Api()
      .baseUrl(this.apiBaseUrl)
      .data({email, password})
      .post('/login');

    ApiSettings.token = [response.data.token_type, response.data.access_token].join(' ');
    ApiSettings.userId = response.data.user_id;

    await this.loginCallback(response);
  }

  /**
   * Logout
   */
  public static async logout() {
    await new Api()
      .baseUrl(this.apiBaseUrl)
      .get('/logout');

    this.reset();
    await this.logoutCallback();
  }

  /**
   * Reset auth info
   */
  public static reset() {
    ApiSettings.clearToken();
    // AppSettings.resetData();

    // resetRouter(); // TODO
  }

  /**
   * Check user is authenticated
   */
  public static get isAuthenticated(): boolean {
    return !!ApiSettings.token;
  }

  /**
   * API base url
   * @protected
   */
  protected static apiBaseUrl: string = process.env.VUE_APP_API_AUTH_URL as string;

  /**
   * Login callback
   * @param response
   * @protected
   */
  protected static async loginCallback(response: AxiosResponse) {
    throw new Error('LoginCallback method not implemented!');
  }

  /**
   * Logout callback
   * @protected
   */
  protected static async logoutCallback() {
    throw new Error('logoutCallback method not implemented!');
  }
}
