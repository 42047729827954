<template>
  <div class="guests-list-types">
    <div
      v-for="(typeData, type) in types"
      :key="type"
      :class="{'guests-list-types__item--active': isActive(type)}"
      class="guests-list-types__item"
      @click="onClick(type)"
    >
      <div
        :class="{'gradient-violet': isActive(type)}"
        class="guests-list-types__item__wrapper"
      >
        <div class="guests-list-types__item__content">
          <span class="guests-list-types__item__icon">
            <icon :name="typeData.icon"/>
          </span>
          <span class="guests-list-types__item__name">
            {{ typeData.name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {typesData} from '../guestsListConfig';
  import Icon from '@/shared/resources/components/Icon.vue';

  export default {
    components: {
      Icon,
    },
    props: {
      value: String,
      onChange: {
        type: Function,
        required: true,
      },
    },

    methods: {
      iconComponent(icon) {
        return 'Icon' + icon;
      },

      isActive(type) {
        return type === this.value;
      },

      onClick(type) {
        this.onChange(type);
      },
    },

    computed: {
      types() {
        return typesData;
      },
    },
  };
</script>

<style lang="scss">
  .guests-list-types {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -7px -7px;

    &__item {
      width: 100%;
      padding: 7px;

      @include sm() {
        width: 50%;
      }

      &__wrapper {
        display: flex;
        padding: 15px 45px 12px 15px;
        background: $white;
        border-radius: 4px;
        box-shadow: 0 0 25px rgb(0 0 0 / 7%);
        height: 100%;
        cursor: pointer;

        @include sm() {
          padding: 20px 50px 16px 20px;
        }
      }

      &--active & {
        &__wrapper {
          color: $white;
          background: $primary;

          &:after {
            display: block;
            content: '\F00C';
            font-family: 'Font Awesome 5 Free';
            color: $white;
            position: absolute;
            margin-top: -11px;
            top: 50%;
            right: 15px;

            @include sm() {
              right: 20px;
            }
          }
        }

        &__icon {
          i {
            color: $white;
          }
        }
      }

      &__content {
        display: flex;
        align-items: center;
      }

      &__icon {
        display: block;
        width: 30px;
        margin-right: 15px;
        line-height: 1;

        i {
          color: $accent;
        }
      }

      &__name {
        font-weight: bold;
        font-size: 1.125em;
      }
    }
  }
</style>
