import {required, requiredIf} from 'vuelidate/lib/validators';
import {types} from '@/app/modules/guests-lists/guestsListConfig';

export default (list: any) => {
  let rules: any = {
    type: {required},
    date: {required},
    place: {required},

    tables: {
      required,

      $each: {
        sides: {
          $each: {
            people: {
              $each: {
                name: {required},
              },
            },
          },
        },
        comments: {
          required: requiredIf(() => {
            return list.type === types.PROM_TYPE;
          }),
        },
      },
    },
  };

  if (list.type === types.WEDDING_TYPE) {
    rules = {
      ...rules,

      newlyweds: {
        bride: {
          name: {required},
          table: {
            sides: {
              $each: {
                people: {
                  $each: {
                    name: {required},
                  },
                },
              },
            },
          },
        },
        groom: {
          name: {required},
          table: {
            sides: {
              $each: {
                people: {
                  $each: {
                    name: {required},
                  },
                },
              },
            },
          },
        },
      },
    };
  }

  return {list: rules};
};
