































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Alert extends Vue {
  /**
   * Props
   */
  @Prop({ default: 'info' }) private type!: string;
  @Prop() private message!: string;
  @Prop() private title?: string;

  /**
   * Get class names
   */
  private get classNames(): object {
    return {
      'custom-alert--with-title': !!this.title,
    };
  }

  /**
   * Get icon
   */
  private get icon(): string {
    switch (this.type) {
      case 'error':
        return 'error';

      case 'warning':
        return 'warning';

      case 'info':
      default:
        return 'info';
    }
  }

  /**
   * Get icon size
   */
  private get iconSize(): string | null {
    return this.title ? '2x' : 'lg';
  }
}
