import Table from './TableModel';
import NewlywedModel from './NewlywedModel';

import {types, typesData} from '../guestsListConfig';

export default class List {
  constructor() {
    this.reset();
  }

  applyData(data) {
    this.type = data.type;

    this.date = data.date;
    this.place = data.place;

    if (data.newlyweds) {
      if (data.newlyweds.bride) {
        this.newlyweds.bride.applyData(data.newlyweds.bride);
      }

      if (data.newlyweds.groom) {
        this.newlyweds.groom.applyData(data.newlyweds.groom);
      }
    }

    if (data.tables) {
      this.tables = data.tables.map(tableData => {
        const table = new Table();
        table.applyData(tableData);

        return table;
      });
    }
  }

  addTable() {
    this.tables.push(this.createTableModel());
  }

  addTableAtIndex(index) {
    this.tables.splice(this.tables, 0, this.createTableModel());
  }

  createTableModel() {
    const table = new Table();

    const lastAddedTable = this.tables.at(-1);
    if (lastAddedTable) {
      table.changeType(lastAddedTable.type);
    }

    return table;
  }

  removeTable(index) {
    this.tables.splice(index, 1);
  }

  setType(type) {
    this.type = type;
    this.setTablesComments();
  }

  setTablesComments() {
    var required = this.tablesCommentsRequired;
    var needed = this.tablesCommentsNeeded;

    this.tables.forEach(function (table) {
      if (!needed) {
        table.setComments(null);
      }

      table.setCommentsRequired(required);
    });
  }

  reset() {
    this.type = null;

    this.date = null;
    this.place = null;

    this.newlyweds = {
      bride: new NewlywedModel(),
      groom: new NewlywedModel()
    };

    this.tables = [new Table()];
  }

  get newlywedsRequired() {
    return this.type === types.WEDDING_TYPE;
  }

  get countTables() {
    return this.tables.length;
  }

  get hasNoTables() {
    return !this.countTables;
  }

  get tablesCommentsNeeded() {
    if (!this.type) {
      return false;
    }

    return !!typesData[this.type].tablesCommentsField
  }

  get tablesCommentsRequired() {
    if (!this.type) {
      return false;
    }

    return typesData[this.type].tablesCommentsField
      ? typesData[this.type].tablesCommentsField.required
      : false;
  }

  get dataToSend() {
    return {
      type: this.type,
      date: this.date,
      place: this.place,
      newlyweds: this._newlywedsDataToSend,
      tables: this._tablesDataToSend
    };
  }

  get _newlywedsDataToSend() {
    if (!this.newlywedsRequired) {
      return undefined;
    }

    return {
      bride: this.newlyweds.bride.dataToSend,
      groom: this.newlyweds.groom.dataToSend,
    };
  }

  get _tablesDataToSend() {
    return this.tables.map(table => {
      return table.dataToSend;
    })
  }

  get hasDataValid() {
    return !!this.date && !!this.place;
  }

  get hasNewlywedsValid() {
    if (!this.newlywedsRequired) {
      return true;
    }

    return this.newlyweds.bride.isValid && this.newlyweds.groom.isValid;
  }

  get hasTablesValid() {
    return this.countTables > 0 && !this.tables.some(table => {
      return !table.isValid;
    });
  }

  get isValid() {
    return this.hasDataValid
      && this.hasNewlywedsValid
      && this.hasTablesValid;
  }
}
