import TableModel from './TableModel';

export default class NewlywedModel {
  constructor() {
    this.name = null;

    const table = new TableModel(false);
    table.changeType(TableModel.TYPE_ROUND);

    this.table = table;
  }

  applyData(data) {
    this.name = data.name;

    if (data.table) {
      this.table.applyData(data.table);
    }
  }

  get dataToSend() {
    return {
      name: this.name,
      table: this.table.dataToSend,
    };
  }

  get hasDataValid() {
    return !!this.name;
  }

  get hasTableValid() {
    return this.table.hasValidSides;
  }

  get isValid() {
    return this.hasDataValid && this.hasTableValid;
  }
}
