<template>
  <div class="guests-list-newlywed">
    <div class="form-group">
      <form-field
        :label="nameLabel"
        :validation="validation.name"
        column
        required
      >
        <input-field
          v-model="newlywed.name"
          :validation="validation.name"
          placeholder="Wpisz..."
        />
      </form-field>
    </div>

    <div class="form-group">
      <label class="control-label d-block font-weight-bold">Dodatkowe osoby</label>

      <guests-list-table-person
        v-for="(person, $personIndex) in newlywed.table.sides[0].people"
        :key="person.timestamp"
        :person="person"
        :validation="validation.table.sides.$each[0].people.$each[$personIndex]"
        :index="$personIndex"
        :initialized="initialized"
        :on-add-person-at-index="addPersonAtIndex"
        :on-delete="removePerson"
        condensed
      ></guests-list-table-person>

      <Button
        color="primary"
        small
        prepend-icon="add"
        @click="addPerson"
      >
        Dodaj osobę
      </Button>
    </div>
  </div>
</template>

<script>
  import GuestsListTablePerson from './GuestsListTablePerson.vue';

  import NewlywedModel from '../models/NewlywedModel';

  import TitleCase from '@/shared/resources/directives/TitleCase.ts';
  import FormField from '@/shared/resources/components/forms/FormField.vue';
  import InputField from '@/shared/resources/components/forms/fields/InputField.vue';
  import Button from '@/shared/resources/components/buttons/Button.vue';

  export default {
    name: 'GuestsListNewlywed',

    components: {
      Button,
      InputField,
      FormField,
      GuestsListTablePerson,
    },

    directives: {
      TitleCase,
    },

    props: {
      newlywed: {
        type: NewlywedModel,
        required: true,
      },

      validation: Object,

      nameLabel: {
        type: String,
        required: true,
      },

      initialized: {
        type: Boolean,
        required: true,
      },
    },

    methods: {
      addPerson() {
        this.newlywed.table.sides[0].addPerson();
      },

      addPersonAtIndex(index) {
        this.newlywed.table.sides[0].addPersonAtIndex(index);
      },

      removePerson(index) {
        this.newlywed.table.sides[0].removePerson(index);
      },
    },
  };
</script>
