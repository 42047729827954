<template>
  <div :class="classes" ref="personElement">
    <div class="guests-list-person__input-wrapper">
      <span class="guests-list-person__input-number">
        {{ index + 1 }}.
      </span>

      <input-field
        v-model="person.name"
        :validation="validation.name"
        :disabled="disabled"
        ref="input"
        placeholder="Wpisz imię i nazwisko..."
        @keypress.enter="addPersonAtIndex(index + 1)"
      />

      <div class="guests-list-person__buttons">
        <Button
          v-tooltip="'Kliknij, aby dodać osobę jako następną.'"
          color="success"
          icon
          @click="addPersonAtIndex(index + 1)"
        >
          <Icon name="add" x-small/>
        </Button>

        <Button
          v-tooltip="'Kliknij, aby usunąć osobę.'"
          color="error"
          icon
          @click="removePerson"
        >
          <Icon name="delete" x-small/>
        </Button>

        <Button
          v-if="displaySetCustomPersonButton"
          v-tooltip="'Kliknij, aby ustawić jako osobę towarzyszącą.'"
          color="default"
          small
          @click="setCustomPerson"
        >
          osoba tow.
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
  import {VTooltip} from 'v-tooltip';

  import Person from '../models/PersonModel';

  // import './../../../tooltip.scss'; TODO
  import TitleCase from '@/shared/resources/directives/TitleCase';
  import Button from '@/shared/resources/components/buttons/Button.vue';
  import Icon from '@/shared/resources/components/Icon.vue';
  import Tooltip from '@/shared/resources/components/Tooltip.vue';
  import InputField from '@/shared/resources/components/forms/fields/InputField.vue';

  const INPUT_REF_PREFIX = 'person';

  export default {
    components: {InputField, Tooltip, Icon, Button},
    directives: {
      tooltip: VTooltip,
      TitleCase,
    },

    props: {
      person: {
        type: Person,
        required: true,
      },

      validation: Object,

      index: {
        type: Number,
        required: true,
      },

      condensed: {
        type: Boolean,
        default: false,
      },

      initialized: {
        type: Boolean,
        required: true,
      },

      onAddPersonAtIndex: {
        type: Function,
        required: true,
      },

      onDelete: {
        type: Function,
        required: true,
      },
    },

    data() {
      return {};
    },

    computed: {
      classes() {
        return {
          'guests-list-person': true,
          'guests-list-person--condensed': this.condensed,
        };
      },

      disabled() {
        return this.person.name === 'Osoba towarzysząca';
      },

      displaySetCustomPersonButton() {
        return !this.person.name;
      },
    },

    created() {
      if (this.initialized) {
        setTimeout(() => {
          this.$refs.input.$el.querySelector('input').focus();
        }, 100);
      }
    },

    methods: {
      addPersonAtIndex(index) {
        this.onAddPersonAtIndex(index);
      },

      setCustomPerson() {
        this.person.name = 'Osoba towarzysząca';
      },

      removePerson() {
        this.onDelete(this.index);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .guests-list-person {
    position: relative;
    margin-bottom: .5rem;

    &__input-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      padding-bottom: 1.25rem;

      @include sm {
        padding-bottom: 0;
      }
    }

    &__input-number {
      font-weight: bold;
      color: $accent;
      z-index: 2;
      width: 2rem;
    }

    &__input {
      width: 100%;
      height: 55px;
      padding: 7px 0 20px 30px;
      border-radius: 0;
      border: 0;
      color: $form-field-border-color;
      background: transparent;

      @include sm() {
        height: 45px;
        padding: 7px 0 12px 30px;
      }

      @include xl() {
        padding: 7px 0 7px 30px;
      }

      &[disabled="disabled"] {
        font-style: italic;
        cursor: not-allowed;
        border-radius: 4px;
      }
    }

    &__buttons {
      white-space: nowrap;
      transition: opacity ease .3s;
      position: absolute;
      right: 0;
      bottom: 0;

      @include sm() {
        position: relative;
        right: unset;
        bottom: unset;
        opacity: 0.5;
        margin-left: .5rem;
      }

      button {
        margin: 0 !important;
      }
    }

    &:hover &, &:focus-within & {
      &__buttons {
        opacity: 1;
      }
    }
  }
</style>
