



































import { Component, Watch, Prop, Vue } from 'vue-property-decorator';

import FormField from '@/shared/resources/components/forms/FormField.vue';
import { Validation } from 'vuelidate';

import Icon from '@/shared/resources/components/Icon.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import Card from '@/shared/resources/components/cards/Card.vue';
import InputField from '@/shared/resources/components/forms/fields/InputField.vue';
import DateRangePresetInterface from '@/shared/lib/interfaces/DateRangePresetInterface';

@Component({
  components: {
    InputField,
    Card,
    Button,
    GridCol,
    GridRow,
    Icon,
    FormField,
  },
})
export default class DateField extends Vue {
  /**
   * Props
   */
  @Prop() private value!: string[];
  @Prop() private name!: string;
  @Prop() private label?: string;
  @Prop() private description?: string;
  @Prop() private validation?: Validation;
  @Prop({ default: false, type: Boolean }) private required!: boolean;
  @Prop({ default: false, type: Boolean }) private solo!: boolean;
  @Prop() private allow?: (date: string) => boolean;
  @Prop() private customPresets?: DateRangePresetInterface[];

  /**
   * Data
   */
  private show: boolean = false;
  private date: string|string[] = this.value;

  /**
   * Methods
   */
  private allowedDates(date: string) {
    return this.allow ? this.allow(date) : true;
  }

  /**
   * Handlers
   */
  private onPickerChange(date: string) {
    this.show = false;
    this.$emit('change', date);
  }

  /**
   * Watcher
   */
  @Watch('value')
  private watchValueChange(value: string[]) {
    this.date = value;
    // this.dateFrom = value[0];
    // this.dateTo = value[1];
  }
}
