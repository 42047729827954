const WEDDING_TYPE: string = 'WEDDING';
const BIRTHDAY_TYPE: string = 'BIRTHDAY';
const PROM_TYPE: string = 'PROM';
const OTHER_TYPE: string = 'OTHER';

const types: any = {
  WEDDING_TYPE,
  BIRTHDAY_TYPE,
  PROM_TYPE,
  OTHER_TYPE,
};

const typesData = {
  [WEDDING_TYPE]: {
    name: 'Ślub i wesele',
    icon: 'favorite_border',
  },
  [BIRTHDAY_TYPE]: {
    name: 'Urodziny',
    icon: 'cake',
  },
  [PROM_TYPE]: {
    name: 'Studniówka',
    icon: 'star_outline',
    tablesCommentsField: {
      label: 'Klasa',
      placeholder: 'Wpisz..',
      required: true,
    },
  },
  [OTHER_TYPE]: {
    name: 'Impreza okolicznościowa',
    icon: 'wine_bar',
  },
};

export {
  types,
  typesData,
};
