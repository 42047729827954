<template>
  <div class="guests-list-data">
    <div class="row">
      <div class="col-md-6">
        <form-field
          :validation="validation.date"
          label="Data"
          column
          required
        >
          <date-field
            v-model="list.date"
            :allow="allowDates"
            :validation="validation.date"
            append-icon="event"
            placeholder="Wybierz..."
            @change="handleDateChange"
          />
        </form-field>
      </div>

      <div class="col-md-6">
        <form-field
          :validation="validation.place"
          label="Miejsce"
          column
          required
        >
          <input-field
            v-model="list.place"
            :validation="validation.place"
            placeholder="Wpisz..."
            required
          />
        </form-field>
      </div>
    </div>
  </div>
</template>

<script>
  import ListModel from '../models/ListModel';

  import GuestsListDataDatePicker from './GuestsListDataDatePicker.vue';
  import TitleCase from '@/shared/resources/directives/TitleCase';
  import Field from '@/shared/resources/components/forms/fields/Field.vue';
  import FormField from '@/shared/resources/components/forms/FormField.vue';
  import InputField from '@/shared/resources/components/forms/fields/InputField.vue';
  import DateField from '@/shared/resources/components/forms/fields/DateField.vue';
  import {isAfterToday} from '@/core/helpers/utils/DateUtils';

  export default {
    name: 'GuestsListData',

    components: {
      DateField,
      InputField,
      FormField,
      Field,
      GuestsListDataDatePicker,
    },

    directives: {
      TitleCase,
    },

    props: {
      list: {
        type: ListModel,
      },
      validation: Object,
    },

    methods: {
      handleDateChange(date) {
        this.list.date = date;
      },

      allowDates(date) {
        return isAfterToday(date);
      },
    },
  };
</script>

<style lang="scss">
  .guests-list-data {
    .col-md-6 {
      @include sm() {
        margin-bottom: 0;
      }
    }
  }
</style>
